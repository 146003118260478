import CustomHead from '@/components/CustomHead';
import AuthForm from '@/components/Forms/auth';
import PageWrapper from '@/components/Layout/PageWrapper';
import { Logo } from '@/components/icons';
import {
  determineRedirectionPath,
  getServerSession,
} from '@/lib/Auth/GetServerSession';
import type { GetServerSidePropsContext } from 'next';
import { getProviders } from 'next-auth/react';
import Link from 'next/link';

const SignIn = ({
  providers,
}: {
  providers: ReturnType<typeof getProviders>;
}) => {
  return (
    <>
      <CustomHead title="Sign In - MindLabs" />
      <div className="pl-10">
        {' '}
        <Link href="https://exploremindlabs.com">
          {' '}
          <Logo width={250} height={100} />
        </Link>
      </div>
      <div className="min-h-screen flex flex-col justify-center sm:py-12">
        <div className=" mx-auto mb-2 "></div>
        <div className="p-5 mx-auto w-full sm:max-w-md">
          <AuthForm isRegisterForm={false} providers={providers} />
        </div>
      </div>
    </>
  );
};

export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const { req } = context;
  const session = await getServerSession({ req });

  if (session) {
    const redirectPath = await determineRedirectionPath(session);

    return {
      redirect: {
        destination: redirectPath,
        permanent: false,
      },
    };
  }
  const providers = await getProviders();

  return {
    props: {
      providers,
    },
  };
};

SignIn.PageWrapper = PageWrapper;
export default SignIn;
